import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/pubblicazione.css'; // You'll need to create this CSS file

function Pubblicazione() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nome: '',
    club: '',
    data_inizio: '',
    data_fine: '',
    locandina: null,
    pdfs: [] // This will now be an array
  });
  const [selectedPdfs, setSelectedPdfs] = useState([]); // To display selected PDF names
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'pdfs') {
      // Handle multiple PDFs
      setFormData(prev => ({
        ...prev,
        pdfs: Array.from(files)
      }));
      setSelectedPdfs(Array.from(files).map(file => file.name));
    } else {
      // Handle single file (locandina)
      setFormData(prev => ({
        ...prev,
        [name]: files[0]
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const formDataToSend = new FormData();
    formDataToSend.append('nome', formData.nome);
    formDataToSend.append('club', formData.club);
    formDataToSend.append('data_inizio', formData.data_inizio);
    formDataToSend.append('data_fine', formData.data_fine);
    
    if (formData.locandina) {
      formDataToSend.append('locandina', formData.locandina);
    }

    // Append multiple PDFs
    if (formData.pdfs.length > 0) {
      formData.pdfs.forEach(pdf => {
        formDataToSend.append('pdfs', pdf);
      });
    }

    try {
      const response = await axios.post(
        `https://${process.env.REACT_APP_API_BASE_URL}/manifestazione`,
        formDataToSend,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'X-Requested-With': 'XMLHttpRequest',
            'Cache-Control': 'no-cache',
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );

      setSuccess(true);
      setTimeout(() => {
        navigate('/manifestazioni');
      }, 2000);
    } catch (err) {
      setError(err.response?.data?.message || 'Si è verificato un errore durante la creazione della manifestazione');
    } finally {
      setLoading(false);
    }
  };



// ... rest of the code ...

  return (
    <div className='create-manifestazione-container'>
      <motion.div
        className='form-container'
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h1>Crea Nuova Manifestazione</h1>
        <hr className='hrBlock' />

        <form onSubmit={handleSubmit} className='manifestazione-form'>
          <motion.div
            className='form-group'
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            <label htmlFor="nome">Nome Manifestazione</label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={formData.nome}
              onChange={handleInputChange}
              required
            />
          </motion.div>

          <motion.div
            className='form-group'
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
          >
            <label htmlFor="club">Club</label>
            <input
              type="text"
              id="club"
              name="club"
              value={formData.club}
              onChange={handleInputChange}
              required
            />
          </motion.div>

          <div className='date-container'>
  <motion.div
    className='form-group'
    initial={{ opacity: 0, x: -50 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: 0.4 }}
  >
    <label htmlFor="data_inizio">Data Inizio (YYYY-MM-DD)</label>
    <input
      type="date"
      id="data_inizio"
      name="data_inizio"
      value={formData.data_inizio}
      onChange={handleInputChange}
      min="2000-01-01"
      max="2099-12-31"
      pattern="\d{4}-\d{2}-\d{2}"
      required
    />
  </motion.div>

  <motion.div
    className='form-group'
    initial={{ opacity: 0, x: -50 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: 0.5 }}
  >
    <label htmlFor="data_fine">Data Fine (YYYY-MM-DD)</label>
    <input
      type="date"
      id="data_fine"
      name="data_fine"
      value={formData.data_fine}
      onChange={handleInputChange}
      min={formData.data_inizio || "2000-01-01"}
      max="2099-12-31"
      pattern="\d{4}-\d{2}-\d{2}"
      required
    />
  </motion.div>
</div>
          <motion.div
            className='form-group'
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.6 }}
          >
            <label htmlFor="locandina">Locandina</label>
            <input
              type="file"
              id="locandina"
              name="locandina"
              onChange={handleFileChange}
              accept="image/*"
              required
            />
          </motion.div>

          <motion.div
          className='form-group'
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.7 }}
        >
          <label htmlFor="pdfs">Documenti PDF (Multipli)</label>
          <input
            type="file"
            id="pdfs"
            name="pdfs"
            onChange={handleFileChange}
            accept=".pdf"
            multiple // Enable multiple file selection
            required
          />
          {selectedPdfs.length > 0 && (
            <div className="selected-files">
              <p>PDF selezionati:</p>
              <ul>
                {selectedPdfs.map((name, index) => (
                  <li key={index}>{name}</li>
                ))}
              </ul>
            </div>
          )}
        </motion.div>

          {error && (
            <motion.div
              className="error-message"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              {error}
            </motion.div>
          )}

          {success && (
            <motion.div
              className="success-message"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              Manifestazione creata con successo!
            </motion.div>
          )}

          <motion.button
            type="submit"
            className='submit-button'
            disabled={loading}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {loading ? 'Creazione in corso...' : 'Crea Manifestazione'}
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
}

export default Pubblicazione;