import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Manifestazioni from './pages/Manifestazioni';
import ChiSiamo from './pages/ChiSiamo';
import Contattaci from './pages/Contattaci';
import { Header, Login } from './components/header';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/footer';
import Galleria from './pages/Galleria';
import Pubblicazione from './pages/Pubblicazione';
import ManifestazionDetail from './pages/ManifestationDetail';
import { AuthProvider } from './Auth/AuthProvider';
import EditManifestazione from './pages/EditManifestazione';
import NewManifestazione from './pages/NewManifestazione';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/chi-siamo" element={<ChiSiamo />} />
        <Route path="/manifestazioni" element={<Manifestazioni />} />
        <Route path="/contattaci" element={<Contattaci />} />
        <Route path="/galleria" element={<Galleria />} />
        <Route path="/login" element={<Login />} />
        <Route path="/pubblicazione" element={<Pubblicazione />} />
        <Route path="/manifestazione/:id" element={<ManifestazionDetail />} />
        <Route path="/manifestazioni/edit/:id" element={<EditManifestazione />} />
        <Route path="/pubblicazione/new" element={<NewManifestazione />} />
      </Routes>
      <Footer />
    </Router>
  </AuthProvider>
);

reportWebVitals();
