import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import '../css/manifestazioni.css';

function Manifestazioni() {
  const [manifestazioni, setManifestazioni] = useState([]);
  const [filteredManifestazioni, setFilteredManifestazioni] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const fetchManifestazioni = async () => {
    try {
      const response = await axios.get(`https://${process.env.REACT_APP_API_BASE_URL}/manifestazioni`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        withCredentials: true,
      });
      
      setManifestazioni(response.data);
      setFilteredManifestazioni(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching manifestazioni:', err);
      setError('Impossibile recuperare le manifestazioni');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchManifestazioni();
    // Check login status
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  // Search functionality
  useEffect(() => {
    const filtered = manifestazioni.filter(manifestazione => 
      manifestazione.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
      manifestazione.club.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredManifestazioni(filtered);
  }, [searchTerm, manifestazioni]);

  const getImageUrl = (path) => {
    return `https://${process.env.REACT_APP_API_BASE_URL}/${path}`;
  };

  if (loading) {
    return (
      <div className="loading-container">
        <motion.div
          className="loading"
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        />
      </div>
    );
  }

  return (
    <div className='manifestazioni-container'>
      <motion.div 
        className="search-container"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <input
          type="text"
          placeholder="Cerca per nome o club..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </motion.div>

      <div className="manifestazioni-grid">
        {filteredManifestazioni.map((manifestazione, index) => (
          <motion.div
            key={manifestazione.id}
            className="manifestazione-card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <div 
              className="image-container"
              onClick={() => navigate(`/manifestazione/${manifestazione.id}`)}
              style={{ cursor: 'pointer' }}
            >
              <img
                src={getImageUrl(manifestazione.locandina)}
                alt={manifestazione.nome}
                className="manifestazione-image"
              />
            </div>
            <div className="manifestazione-info">
              <h3>{manifestazione.nome}</h3>
              <p>{manifestazione.club}</p>
              <p>Dal {new Date(manifestazione.data_inizio).toLocaleDateString()} 
                  al {new Date(manifestazione.data_fine).toLocaleDateString()}</p>
              {isLoggedIn && (
                <div className="button-group">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => navigate(`/manifestazioni/edit/${manifestazione.id}`)}
                  >
                    Modifica
                  </motion.button>
                </div>
              )}
            </div>
          </motion.div>
        ))}
      </div>

      {error && (
        <motion.div
          className="error-message"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {error}
        </motion.div>
      )}

      {filteredManifestazioni.length === 0 && !error && (
        <motion.div
          className="no-results"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          Nessuna manifestazione trovata
        </motion.div>
      )}
    </div>
  );
}

export default Manifestazioni;