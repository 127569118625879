import { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from 'axios';
import "../css/header.css";
import logo from "../images/logo.png";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://${process.env.REACT_APP_API_BASE_URL}/login`, {
        username,
        password,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        withCredentials: true,
      });
      const token = response.data.token;
      localStorage.setItem("token", token);
      localStorage.setItem("isLoggedIn", "true");
      setError(null);
      navigate("/");
      window.location.reload();
    } catch (err) {
      setError(err.response?.data?.error || "Errore durante il login");
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "50px auto", textAlign: "center", marginTop: "100px" }}>
      <h3>Login</h3>
      <form onSubmit={handleLogin}>
        <div style={{ marginBottom: "15px" }}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{ width: "100%", padding: "10px" }}
            required
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ width: "100%", padding: "10px" }}
            required
          />
        </div>
        <button
          type="submit"
          style={{ padding: "10px 20px", backgroundColor: "#Ff7514", color: "white", border: "none", cursor: "pointer" }}
        >
          Login
        </button>
      </form>
      {error && <p style={{ color: "red", marginTop: "15px" }}>{error}</p>}
    </div>
  );
}

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check authentication status on component mount and token changes
    const checkAuth = () => {
      const token = localStorage.getItem("token");
      const loggedIn = localStorage.getItem("isLoggedIn") === "true";
      setIsLoggedIn(!!token && loggedIn);
    };

    checkAuth();
    window.addEventListener("storage", checkAuth);

    return () => {
      window.removeEventListener("storage", checkAuth);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.setItem("isLoggedIn", "false");
    setIsLoggedIn(false);
    navigate("/");
  };
  
  return (
    <>
      <span id="top"></span>

      <motion.nav
        className={`bg ${menuOpen ? "menu-open" : ""}`}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div className="logop" whileHover={{ scale: 1.05 }}>
          <Link to="/">
            <img src={logo} alt="logo" className="logo" />
          </Link>
        </motion.div>

        <motion.div
    className={`infop ${menuOpen ? "open" : "closed"}`}
    animate={menuOpen ? { x: 0 } : { x: -10 }}
    transition={{ duration: 0.5 }}
  >
    <NavLink
      to="/"
      className="nav-link mr10"
      end
      onClick={() => {
        window.scrollTo(0, 0);
        toggleMenu();
      }}
    >
      Home
    </NavLink>
    <NavLink
      to="/manifestazioni"
      className="nav-link mr10"
      onClick={() => {
        window.scrollTo(0, 0);
        toggleMenu();
      }}
    >
      Manifestazioni
    </NavLink>
    <NavLink
      to="/chi-siamo"
      className="nav-link mr10"
      onClick={() => {
        window.scrollTo(0, 0);
        toggleMenu();
      }}
    >
      Chi Siamo
    </NavLink>
    <NavLink
      to="/contattaci"
      className="nav-link mr10"
      onClick={() => {
        window.scrollTo(0, 0);
        toggleMenu();
      }}
    >
      Contattaci
    </NavLink>
    <NavLink
      to="/galleria"
      className="nav-link mr10"
      onClick={() => {
        window.scrollTo(0, 0);
        toggleMenu();
      }}
    >
      Galleria
    </NavLink>
    {isLoggedIn ? (
      <>
        <NavLink
          to="/pubblicazione"
          className="nav-link mr10"
          onClick={() => {
            window.scrollTo(0, 0);
            toggleMenu();
          }}
        >
          Pubblicazione
        </NavLink>
        <button
          className="nav-link mr10 logout-button"
          onClick={() => {
            handleLogout();
            toggleMenu();
          }}
        >
          Logout
        </button>
      </>
    ) : (
      <NavLink
        to="/login"
        className="nav-link mr10"
        onClick={() => {
          window.scrollTo(0, 0);
          toggleMenu();
        }}
      >
        Login
      </NavLink>
    )}
  </motion.div>

        <motion.button
          className="menu-toggle"
          onClick={toggleMenu}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", stiffness: 200, damping: 15 }}
        >
          <motion.span
            className="line top"
            animate={menuOpen ? { rotate: 45, y: 8 } : { rotate: 0, y: 0 }}
            transition={{ type: "spring", stiffness: 200, damping: 15 }}
          />
          <motion.span
            className="line middle"
            animate={menuOpen ? { opacity: 0 } : { opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          />
          <motion.span
            className="line bottom"
            animate={menuOpen ? { rotate: -45, y: -8 } : { rotate: 0, y: 0 }}
            transition={{ type: "spring", stiffness: 200, damping: 15 }}
          />
        </motion.button>
      </motion.nav>

      <motion.button
        className="topPage"
        onClick={() => {
          const topElement = document.getElementById("top");
          if (topElement && typeof topElement.scrollIntoView === "function") {
            topElement.scrollIntoView({ behavior: "smooth", block: "start" });
          } else {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        }}
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.2 }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="white"
          width="30px"
          height="30px"
        >
          <path d="M12 2l8.5 8.5-1.41 1.41L13 6.83V22h-2V6.83l-6.09 6.09L3.5 10.5z" />
        </svg>
      </motion.button>
    </>
  );
}

export { Login, Header };