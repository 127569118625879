import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/editManifestazione.css'; // We can reuse the edit styles

function NewManifestazione() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    club: '',
    data_inizio: '',
    data_fine: '',
  });
  const [locandina, setLocandina] = useState(null);
  const [pdfs, setPdfs] = useState([]);

  // Add useEffect to watch isLoading state
  useEffect(() => {
    if (isLoading) {
      navigate('/');
    }
  }, [isLoading, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLocandinaChange = (e) => {
    if (e.target.files[0]) {
      setLocandina(e.target.files[0]);
    }
  };

  const handlePdfsChange = (e) => {
    if (e.target.files) {
      setPdfs(Array.from(e.target.files));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    const formDataToSend = new FormData();

    // Append text fields
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });

    // Append files
    if (locandina) {
      formDataToSend.append('locandina', locandina);
    }
    
    pdfs.forEach(pdf => {
      formDataToSend.append('pdfs', pdf);
    });

    try {
      console.log(formDataToSend);
      const response = await axios.post(`https://${process.env.REACT_APP_API_BASE_URL}/manifestazioni/`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data) {
        alert('Manifestazione creata con successo!');
        console.log(response.data);
        navigate('/manifestazioni', { state: { refresh: true } });
      }
    } catch (err) {
      console.error('Error creating manifestazione:', err);
      alert('Errore durante la creazione della manifestazione');
      setIsLoading(false);
    }
  };

  // Add useEffect to clean up any pending timeouts when component unmounts
  useEffect(() => {
    return () => {
      // This will clear any pending timeouts when the component unmounts
      const timeouts = setTimeout(() => {}, 0);
      for (let i = 0; i < timeouts; i++) {
        clearTimeout(i);
      }
    };
  }, []);

  return (
    <div className="edit-manifestazione-container">
      <h1>Aggiungi Nuova Manifestazione</h1>
      
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="nome">Nome:</label>
          <input
            type="text"
            id="nome"
            name="nome"
            value={formData.nome}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="club">Club:</label>
          <input
            type="text"
            id="club"
            name="club"
            value={formData.club}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="data_inizio">Data Inizio:</label>
          <input
            type="date"
            id="data_inizio"
            name="data_inizio"
            value={formData.data_inizio}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="data_fine">Data Fine:</label>
          <input
            type="date"
            id="data_fine"
            name="data_fine"
            value={formData.data_fine}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="locandina">Locandina:</label>
          <input
            type="file"
            id="locandina"
            name="locandina"
            onChange={handleLocandinaChange}
            accept="image/*"
            required
          />
          {locandina && (
            <div className="file-preview">
              <p>File selezionato: {locandina.name}</p>
            </div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="pdfs">PDF (opzionali):</label>
          <input
            type="file"
            id="pdfs"
            name="pdfs"
            onChange={handlePdfsChange}
            multiple
            accept=".pdf"
          />
          {pdfs.length > 0 && (
            <div className="file-preview">
              <p>File selezionati:</p>
              <ul>
                {pdfs.map((pdf, index) => (
                  <li key={index}>{pdf.name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="button-group">
          <button 
            type="submit" 
            className="save-button"
            disabled={isLoading}
          >
            {isLoading ? 'Creazione in corso...' : 'Crea Manifestazione'}
          </button>
          <button 
            type="button" 
            className="cancel-button"
            onClick={() => navigate('/')}
            disabled={isLoading}
          >
            Annulla
          </button>
        </div>
      </form>
    </div>
  );
}

export default NewManifestazione; 